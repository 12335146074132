import { mapGetters } from "vuex";
import * as types from "../../../../store/types";
import transactionsService from "../../../../services/transactionsService";
import $formatNumber from "../../../../mixins/formatNumber";

export default {
  name: "failed-webhook",
  components: {},
  props: [],
  mixins: [$formatNumber],
  data() {
    return {
      webHooks: "",
      currentPage: 1,
      tableHeaders: ["نام شرکت", "ارز پرداختی", "ارز دریافتی", "عملیات"],
      tableHeaders2: ["نام شرکت", "ارز پرداختی", "ارز دریافتی"]
      // isCommand: false,
      // isWrite: false
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO
    }),
    isCommand() {
      return this.userInfo.permissions["ADMIN_WEBHOOKS"].command;
    },
    isWrite() {
      return this.userInfo.permissions["ADMIN_WEBHOOKS"].write;
    },
    getTableHeaders() {
      return !this.isCommand ? this.tableHeaders2 : this.tableHeaders;
    }
  },
  mounted() {
    this.getFailedWebHookFunc();
    // this.isCommand = this.userInfo.permissions["ADMIN_WEBHOOKS"].command;
    // this.isWrite = this.userInfo.permissions["ADMIN_WEBHOOKS"].write;
    // if (!this.isCommand) {
    //   this.tableHeaders = ["نام شرکت", "ارز پرداختی", "ارز دریافتی"];
    // }
  },
  methods: {
    getFailedWebHookFunc() {
      transactionsService
        .getFailedWebHook(0, localStorage.getItem("bitrahAdminAccessToken"))
        .then(response => {
          this.webHooks = response.data;
        });
    },
    sendAgain(data, index) {
      transactionsService
        .resendWebHook(data, localStorage.getItem("bitrahAdminAccessToken"))
        .then(response => {
          if (response.data) {
            this.evacuation.content.splice(index, 1);
            this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
              title: this.$i18n.t("toast.successTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successFontColor rtl text-right font-weight-bold py-3",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
          } else {
            this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    },
    getPaginationData(id) {
      transactionsService
        .getFailedWebHook(
          id - 1,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type
        )
        .then(response => {
          this.webHooks = response.data;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    }
  }
};
